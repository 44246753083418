export function load(
  locale = 'en',
  isProd = process.env.NODE_ENV === 'production',
) {
  const { messages } = isProd
    ? require(`../../translations/${locale}/messages`)
    : require(`../../translations/${locale}/messages.po`);

  return messages;
}

export default {
  en: load('en'),
  es: load('es'),
  'es-MX': load('es-MX'),
  fr: load('fr'),
  'fr-CA': load('fr-CA'),
  it: load('it'),
  de: load('de'),
  nl: load('nl'),
  pl: load('pl'),
  pt: load('pt'),
  'pt-BR': load('pt-BR'),
  ru: load('ru'),
  tr: load('tr'),
  ko: load('ko'),
  ja: load('ja'),
  zh: load('zh'),
  vi: load('vi'),
};
